import React from 'react'
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Errors from "../components/errors";


import Layout from '../components/layout'
import SEO from '../components/seo'


export const query = graphql`
  query Page404Query {
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }
  }
`;



const NotFoundPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return <Errors errors={errors} />;
  }

  console.log('asd', data.page.navMenu.items)

  return (
  <Layout navMenuItems={data.page.navMenu.items} showNav>
    <SEO title='404 - Page not found' />
    <div className="py-32 w-3/5 mx-auto">
      <h1 className="w-full my-2 text-5xl font-bold leading-tight ">Page not found</h1>
      <p className="pb-2">A page for this URL was not found. It might be because of a mistake in the address or a broken link.</p>
      <p><Link to="/" className="text-blue">Go to home</Link> to try to get what you are looking for in another way.</p>
    </div>

  </Layout>

  )
}


export default NotFoundPage
